import { Controller } from '@hotwired/stimulus';
import YouTubePlayer from 'youtube-player';

const YT_PLAYER_ID_PREFIX = 'youtube-player';

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['player'];
  static values = {
    playerId: String,
    autoplay: Number
  }

  initialize() {
    this.isPlayerReady = false;
  }

  connect() {

    // Establish bindings to relevant functions:
    this.initializePlayer = this.initializePlayer.bind(this);
    this.handleJump = this.handleJump.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);

    // listen for events coming from the episode_button Controller
    document.addEventListener('jumpToTime', this.handleJump);

    this.initializePlayer();
  }

  disconnect() {
    // Don't respond to these events after disconnection
    document.removeEventListener('jumpToTime', this.handleJump);
  }

  initializePlayer() {
    // Initialize the YouTube player by specifying the target HTML element and options

    // Issue with Previous Implementation:
    // - The old code initialized the player without specifying the 'videoId' in the options.
    // - It created the player using:
    //     this.player = YouTubePlayer(YT_PLAYER_ID_PREFIX + "-" + this.playerIdValue);
    // - Then it loaded the video separately using:
    //     this.player.loadVideoById(this.playerIdValue);
    //
    // Why This Caused a Blurry Thumbnail:
    // - Initializing without 'videoId' results in an iframe whose 'src' attribute lacks the video ID.
    // - This causes the YouTube player to load without a specific video initially.
    // - As a result, the player displays a default or low-resolution thumbnail.
    //
    // How the New Implementation Fixes the Issue:
    // - By including 'videoId' in the initialization options, the iframe's 'src' includes the video ID from the start.
    // - This ensures the correct video is loaded immediately when the player initializes.
    // - The high-resolution thumbnail is displayed without delay, eliminating the blurriness.
    // - We no longer need to call 'loadVideoById' separately, simplifying the code and improving performance.

    this.player = YouTubePlayer(`${YT_PLAYER_ID_PREFIX}-${this.playerIdValue}`, {
      videoId: this.playerIdValue // Specify the video ID during initialization
    });

    // Register the 'ready' event handler to perform actions when the player is fully initialized
    this.player.on('ready', this.onPlayerReady);
  }

  // This is triggered when the player is ready
  onPlayerReady(event) {
    this.isPlayerReady = true;

    // If autoplay is enabled, play the video
    if (this.autoplayValue == 1) {
      console.log('Autoplay is enabled, playing the video');
      this.player.playVideo();
    } else {
      // Autoplay is disabled, pause the video to keep it ready for seeking
      this.player.pauseVideo();

      // Explanation:
      // - Using pauseVideo() keeps the player in the 'paused' state (state code 2)
      // - In the 'paused' state, methods like seekTo() — called in the handleJump function — function properly
      // - This allows us to:
      // 1) Load the episode page 
      // 2) Click a timeline button to jump to a specific time
      // 3) Have the video start playing from that timestamp (based on logic in the handleJump function)
      //
      // Why not use stopVideo()?
      // - stopVideo() resets the player to the 'unstarted' state (state code -1)
      // - In the 'unstarted' state, seekTo() does not work properly
      // - This would prevent users from seeking to a specific time before the video starts playing 
      //
      // Therefore, pauseVideo() ensures the player is ready for user interactions like seeking.
    }
  }

  handleJump(event) {
    // Parse the time from the event detail and ensure it's a floating point number
    // This is because the seekTo method (see below) accepts a float
    const time = parseFloat(event.detail.time);
    
    if (!this.player) {
      console.log('No player instance available');
      return;
    }

    if (!this.isPlayerReady) {
      console.log('Player is not ready');
      return;
    }

    // Get the current player state
    this.player.getPlayerState().then((state) => {
      // Player states:
      // -1: unstarted
      //  0: ended
      //  1: playing
      //  2: paused
      //  3: buffering
      //  5: video cued (this is the state when clicking the timeline button for the first time before the video has started playing)

      if (state === -1 || state === 0 || state === 5) {
        // If the player is unstarted (state -1), ended (state 0), or video cued (state 5), start playing first
        this.player.playVideo().then(() => {
          // After playback starts, seek to the specified time
          // The seekTo method accepts a second parameter (called "allowSeekAhead") which is a boolean.
          // When set to true, it allows the player to seek to a point in the video that hasn't been buffered yet.
          // This is crucial for seeking to any time in the video, regardless of buffering state
          this.player.seekTo(time, true);
        });
      } else {
        // If the player is playing (state 1) or paused (state 2), seek immediately
        this.player.seekTo(time, true); 
        // Optionally, play the video if it's paused
        if (state !== 1) {
          this.player.playVideo();
        }
      }
    });
  }
}
