import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Notes:
  // This controller is used in aliquot_episodes/index.html.erb (the page where we list all the Aliquot episodes)
  // It handles one variant of the 'aliquot_episode_title_interaction' field test for non-subscribers
  //
  // Field Test Details:
  // - Name: aliquot_episode_title_interaction
  // - Variant: "open_subscription_modal" (this controller handles this variant)
  // - Purpose: Tests whether showing a subscription modal increases conversion vs. linking to episode page
  //
  // When the page loads, Stimulus looks for elements with data-controller="aliquot-subscription-modal"
  // These elements are the episode titles 
  // The click event is bound via data-action="click->aliquot-subscription-modal#openModal"
  
  // This method is called when a non-subscriber clicks an episode title and they are part of the "open_subscription_modal" variant in the field test
  // Instead of creating a new modal instance, we reuse the existing subscription modal
  // that's already present on the page via the Core::ModalComponent
  openModal(event) {
    // Prevent the default link behavior
    // This is important because the episode titles look like links (with underlines)
    // but we want to control the click behavior
    event.preventDefault()
    
    // Find the existing "Get access" button that's already connected to Core::ModalComponent
    // We use this approach because:
    // 1. The modal is already rendered on the page via Core::ModalComponent
    // 2. We avoid duplicating modal instances which could cause conflicts
    const existingButton = document.querySelector('button.primary')
    
    // Programmatically trigger a click on the button (this opens the modal)
    // This leverages the existing modal infrastructure:
    // - Uses the same modal instance that's already on the page
    // - Maintains consistent behavior with the main "Get access" button
    existingButton.click()
  }
}